<!-- src/App.vue -->
<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <PartialHeader />
    <div class="content flex-grow-1">
      <router-view />
    </div>
    <PartialFooter />
  </div>
</template>


<script>
import PartialHeader from './components/PartialHeader.vue'
import PartialFooter from './components/PartialFooter.vue'

export default {
  name: 'App',
  components: {
    PartialHeader,
    PartialFooter,
  },
}
</script>
