<template>

    <div class="container my-5">
      <div class="row">
        <div class="col">
          <h2>How to use our snapshots to run a Q node for testnet and mainnet</h2>
          <div>
          <p>Our snapshots are available as .gz archives at <a href="https://q-snapshots.s3.fr-par.scw.cloud/archive.gz">https://q-snapshots.s3.fr-par.scw.cloud/archive.gz</a> for the mainnet snapshots, and <a href="https://q-snapshots.s3.fr-par.scw.cloud/testnet-archive.gz">https://q-snapshots.s3.fr-par.scw.cloud/testnet-archive.gz</a> for the testnet snapshots.<br>
          How to use with the testnet-public-tools provided by Q :</p>
          </div>
          <div class="code-snippet">
            <button class="btn btn-secondary" @click="copyCode()">Copy</button>
            <pre><code ref="codeSnippet"># Navigate to the testnet-validator directory
cd /path/to/testnet-public-tools/testnet-validator

# Stop the currently running node
docker-compose down

# Clear the old storage
rm -rf /var/lib/docker/volumes/testnet-validator_testnet-validator-node-data/_data/geth/chaindata/*

# Make sure the node storage folder exists
mkdir -p /var/lib/docker/volumes/testnet-validator_testnet-validator-node-data/_data/geth/chaindata/

# Download and decompress the latest testnet snapshot from Qollective.io
wget -O - https://q-snapshots.s3.fr-par.scw.cloud/testnet-archive.gz | tar xf -C /var/lib/docker/volumes/testnet-validator_testnet-validator-node-data/_data/geth/chaindata/ -

# Restart the node
docker-compose up -d</code></pre>
          </div>
          <p>This will work just the same for full nodes and mainnet nodes. Just make sure to adapt these commands to the node you're running.</p>
        </div>
      </div>
    </div>
</template>


<script>
import copy from 'clipboard-copy'

export default {
  name: 'SnapshotsService',
  methods: {
    async copyCode() {
      const codeElement = this.$refs.codeSnippet
      if (codeElement) {
        try {
          await copy(codeElement.textContent)
          console.log('Copied to clipboard')
        } catch (e) {
          console.error('Failed to copy', e)
        }
      } else {
        console.error('Code element not found')
      }
    },
  },
}
</script>
