<template>
	<div class="container my-5">
        

		<h1 class="text-center mb-5">Terms of Use</h1>
		<p>By accessing and using this website, you accept and agree to be bound by the following terms and conditions:</p>
		<ol>
			<li>You must be considered old enough to use financial products according to your local legislation.</li>
			<li>You may not use this website for any illegal or unauthorized purpose.</li>
			<li>You may not modify, adapt, or hack this website or attempt to gain unauthorized access to the website.</li>
			<li>We reserve the right to terminate your use of the website for any reason, including a violation of these terms of use.</li>
			<li>This website may contain links to other websites, which are not owned or controlled by us. We are not responsible for the content or practices of any linked websites.</li>
			<li>This website is provided on an "as is" and "as available" basis, without any warranties of any kind.</li>
			<li>We reserve the right to modify or discontinue the website at any time without notice.</li>
			<li>These terms of use may be updated from time to time. It is your responsibility to check back for any changes.</li>
		</ol>
		<p>If you have any questions or concerns about these terms of use, please contact us at contact@qollective.io.</p>
		<p><em>Last updated: March 11th 2023</em></p>

	</div>

</template>


<script>
export default {
  name: 'TermsOfUse',
}
</script>