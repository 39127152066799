<template>

    <h1 class="text-center my-4">Our nodes</h1>

    <div class="container my-5">








        <div class="container my-5">
            <div class="row">
              <div class="col-md-10 mx-auto">









        


    <table class="table table-hover">
        <tbody>
            <tr class="fw-bold text-decoration-underline disable-pointer-events">   
                <td class="border-0" scope="col"></td>
                <td class="border-secondary border-top-0 border-bottom-0" scope="col"  rowspan="6"></td>
                <td class="border-secondary border text-center align-middle mainnet-testnet" colspan="3">Mainnet</td>
                <td class="border-secondary border-top-0 border-bottom-0" scope="col" rowspan="6"></td>
                <td class="border-secondary border text-center align-middle mainnet-testnet" colspan="3">Testnet</td>
            </tr>
        <tr class="fw-bold text-decoration-underline disable-pointer-events">   
            <td class="col-sm-2 border-secondary border text-center" scope="col">Role</td>
            <td class="border-secondary border text-center" scope="col"  colspan="2">Name</td>
            <td class="border-secondary border text-center" scope="col">Status</td>
            <td class="border-secondary border text-center" scope="col"  colspan="2">Name</td>
            <td class="border-secondary border text-center" scope="col">Status</td>
        </tr>
            <tr>
                <td class="border-secondary border text-center premium">Premium RPC</td>
                <td class="text-center emoji-cell !important border-secondary border-start !important">🎂</td>
                <td class="text-left bday-cake name-cell !important">Bday cake</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill  grey-light" data-bs-trigger="hover" data-bs-toggle="popover" data-bs-content="Contact us to get a dedicated Premium RPC node for you or your organisation">On-demand *</span></td>


                <td class="border-secondary border text-center name-cell !important" colspan="3">-</td>
            </tr>
            <tr>
                <td class="border-secondary border text-center">RPC</td>
                <td class="text-center emoji-cell !important border-secondary border-start !important">🍦</td>
                <td class="text-left vanilla name-cell !important">Vanilla</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill  green-light">Online</span></td>

                <td class="text-center emoji-cell !important border-secondary border-start !important">🍫</td>
                <td class="text-left chocolate name-cell !important">Chocolate</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill green-light">Online</span></td>
            </tr>
            <tr>
                <td class="border-secondary border text-center">Validator</td>
                <td class="text-center emoji-cell !important border-secondary border-start !important">🍓</td>
                <td class="text-left strawberry name-cell !important">Strawberry</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill green-light">Online</span></td>

                <td class="text-center emoji-cell !important border-secondary border-start !important">🫐</td>
                <td class="text-left blueberry name-cell !important">Blueberry</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill green-light">Online</span></td>
            </tr>
            <tr>
                <td class="border-secondary border text-center">Snapshot</td>
                <td class="text-center emoji-cell !important border-secondary border-start !important">🍪</td>
                <td class="text-left cookie name-cell !important">Cookie</td>
                <td class="border-secondary border text-center"><span class="badge rounded-pill yellow-light" data-bs-trigger="hover" data-bs-toggle="popover" data-bs-content="This node is not yet running, but its features are being temporarily handled by other nodes in our network">Provisional *</span></td>
                

                    <td class="text-center emoji-cell !important border-secondary border-start !important">🍩</td>
                    <td class="text-left doughnut name-cell !important">Doughnut</td>
                    <td class="border-secondary border text-center"><span class="badge rounded-pill yellow-light" data-bs-trigger="hover" data-bs-toggle="popover" data-bs-content="This node is not yet running, but its features are being temporarily handled by other nodes in our network">Provisional *</span></td>
                
            </tr>
        </tbody>
    </table>
    
</div>
<div class="my-5">
    <h4 class="text-center">Node roles</h4>
    <ul class="my-4">
        <li class="my-4"><u class="premium">Premium RPC</u> : These are the cream of the crop of RPC nodes : <u>highest throughput</u>, <u>lowest latency</u>, and perhaps most importantly, <u>custom locations around the world</u> with direct peering to validator nodes for the <u>fastest transaction processing</u> for users and organisations with the highest needs.<br>
            <a href="/services/premium">Check out our premium offerings!</a>
        </li>
        <li><u>RPC</u> : allows API access to some endpoints of a Q node, allowing users to connect crypto wallets like <b>Metamask</b> to the Q blockchain.<br>
            <a href="/services/rpc">Use our nodes to connect to the Q network!</a>
        </li>
        <li class="my-4"><u>Validator</u> : is responsible for creating the blocks that make up the blockchain, and generates <b>staking rewards</b> for the node owners and delegators<br>
            <a href="/services/delegate">Learn more about delegation and start earning Q tokens.</a>
        </li> 
        <li class="my-4"><u>Snapshot</u> : regularily generates snapshots of the entire blockchain for other node operators to use and bootstrap their nodes at lightning speed ⚡<br>
            <a href="/services/snapshots">Get informed on how to use our snapshots.</a>
        </li>
      </ul>
    <p></p>

</div>
</div>
</div>
</div>



  <footer id="footer"></footer>

</template>


<script>
import { Popover } from 'bootstrap'

export default {
  name: 'NodeList',
  mounted() {
    this.$nextTick(() => {
      const popoverElements = document.querySelectorAll('[data-bs-toggle="popover"]')

      popoverElements.forEach(element => {
        new Popover(element, {
          trigger: element.getAttribute('data-bs-trigger') || 'hover',
          content: element.getAttribute('data-bs-content'),
          placement: 'top',
        })
      })
    })
  },
}
</script>

