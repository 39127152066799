<template>
	<div class="container my-5">
		<h1 class="text-center mb-5">Privacy Policy</h1>
		<p class="lead text-center">Qollective.io is committed to protecting the privacy of its users.</p>
        <h2>Purpose</h2>
        <p>We may log user's connection details when using our services exclusively for security purposes.<br>
        We do not share this data with third parties, and do not use it for commercial purposes.<br>
        User logs are automatically deleted after 365 days.</p>
		<hr>
		<h2>Security</h2>
		<p>We have implemented security measures to ensure the safety of our users' information.<br>
        User logs are stored on a secure private storage managed by our server provider, and exclusively accessible to authorized Qollective.io personnel.</p>
		<hr>
		<h2>Changes to This Privacy Policy</h2>
		<p>We may update this privacy policy from time to time. Users should check back for any changes.</p>
		<hr>
		<h2>Contact Us</h2>
		<p>If you have any questions or concerns about our privacy policy, please contact us at contact@qollective.io .</p>
		<p><em>Last updated: March 11th 2023</em></p>
	</div>
</template>


<script>
export default {
  name: 'PrivacyPolicy',
}
</script>