<template>
    <div class="container my-5">
      <h1 class="text-center">About Us</h1>
        <p class="lead text-center">
            The Qollective is a passion project that aims to make the Q blockchain accessible to users, builders, businesses and creators.<br>
        </p>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Matthieu Saubin</h5>
              <p class="card-text">Web3 Ambassador</p>
            <a href="https://www.linkedin.com/in/msaubin/" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'linkedin']" class="fa-2x social-icon" />
            </a>
            <a href="https://twitter.com/Katalyster" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'twitter']" class="fa-2x social-icon" />
            </a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Gaétan Lajeune</h5>
              <p class="card-text">Editor-in-chief of <a href="https://coinpri.com" target="_blank">coinpri.com</a></p>
            <a href="https://www.linkedin.com/in/glajeune" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'linkedin']" class="fa-2x social-icon" />
            </a>
            <a href="https://twitter.com/Tramirostronix" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'twitter']" class="fa-2x social-icon" />
            </a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Guillaume Heu</h5>
              <p class="card-text">Technical expert</p>
            <a href="https://www.linkedin.com/in/guillaume-heu-85a15315b/" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'linkedin']" class="fa-2x social-icon" />
            </a>
            <a href="https://github.com/guillheu" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'github']" class="fa-2x social-icon" />
            </a>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>




<script>
export default {
  name: 'AboutUs',
}
</script>