<template>
    <div class="container my-5">
      <h1>Delegate your Q tokens to a validator</h1>
      <p>Delegation is a great way to secure the Q network and earn Q tokens as a result, no matter the amount of tokens you wish to stake!<br>
        However, it is important to remember that delegation means entrusting a validator to keep their validator node running to avoid risks of being slashed.<br>
        Delegating for a validator that's not earning rewards (for instance, if their node is down) can lead to missed rewards for the delegators.<br>
        Check out the <a href="https://docs.qtestnet.org/how-to-delegate-to-validator/">Official Q documentation</a> for more info on how to delegate.<br>
      <br>
      </p>

      <p>More info on staking and delegation can be found in the <a href="https://docs.q.org/dapp-staking/">official Q documentation</a></p>
    </div>

</template>


<script>
export default {
  name: 'DelegateService',
}
</script>