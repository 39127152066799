<!-- src/pages/NotFound.vue -->
<template>

    <div class="container my-5 text-center">


      <h1>404</h1>
      <p>Oops! The page you're looking for cannot be found.</p>
      <p><a href="/">Go back to homepage</a></p>
    </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
