<!-- src/pages/ContactUs.vue -->
<template>
    <div class="container my-5 text-center">
    <h1>Contact Us</h1>
    <p>If you have any questions or concerns, feel free to reach out to us via email:</p>
    <p>
      <a :href="`mailto:${emailAddress}`" target="_blank" rel="noopener noreferrer">{{ emailAddress }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      emailAddress: 'contact@coinpri.com',
    };
  },
};
</script>
