<!-- src/pages/ServerError.vue -->
<template>

    <div class="container my-5 text-center">


      <h1>Internal server error</h1>
      <p>Looks like something went wrong on our end. Please try again later.<br>If the problem persists, please <a href="/contact">contact us</a></p>
      <p><a href="/">Go back to homepage</a></p>
    </div>
</template>

<script>
export default {
  name: 'ServerError',
};
</script>
