<template>
    <div class="container my-5">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <h1 class="text-center mb-4">Welcome to the Q.ollective</h1>
          <h4 class="text-center mb-4">Your Gateway into the <u>Q Blockchain</u><br>and the Future of <u>Web3 Governance</u></h4>
          
          

              <h2 class="text-center mb-4">Our services</h2>
              <div class="row">
                <div class="col-sm-4 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title"><a href="/services/rpc">Network access through RPC</a></h3>
                      <p class="card-text">Connect your crypto wallet to our RPC node to access the Q mainnet and testnet.</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title"><a href="/services/delegate">Validation and delegation</a></h3>
                      <p class="card-text">Learn how delegating your Q tokens can secure the Q blockchain and earn you rewards.</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title"><a href="/services/snapshots">Snapshots and archives</a></h3>
                      <p class="card-text">Access historical snapshots and archives of the Q blockchain.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title text-center"><a href="/services/premium" class="premium"><b>Premium RPC for demanding users</b></a></h3>
                      <p class="card-text">Our special Premium RPC is perfect for users with high-stake and time sensitive uses on the Q blockchain, with extremely low latency and dedicated bandwidth.</p>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="text-center mb-4">More resources</h2>
              <div class="row">
                <div class="col-sm-6 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title"><a href="/about_us">More about us</a></h3>
                      <p class="card-text">Learn more about our team and our mission to revolutionize Web3 governance.</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mb-4">
                  <div class="card h-100">
                    <div class="card-body">
                      <h3 class="card-title"><a target="_blank" href="https://q.org/about-q">More about Q</a></h3>
                      <p class="card-text">Explore the Q blockchain and learn about its features and capabilities.</p>
                    </div>
                  </div>
                </div>
              </div>

        </div>
      </div>
    </div>

</template>


<script>
export default {
  name: 'HomePage',
}
</script>