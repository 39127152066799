<!-- src/components/Footer.vue -->
<template>
  <div class="bg-dark text-white py-3 footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>&copy; 2023 Qollective</p>
        </div>
        <div class="col-md-6 text-md-end">
          <a href="/privacy_policy">Privacy Policy</a>
          <br>
          <a href="/terms_of_use">Terms of Use</a>
          <br>
          <a href="/contact_us">Contact us</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartialFooter',
}
</script>
