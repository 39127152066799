// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import AboutUs from '@/pages/AboutUs.vue'
import NodeList from '@/pages/NodeList.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue'
import TermsOfUse from '@/pages/TermsOfUse.vue'
import DelegateService from '@/pages/services/DelegateService.vue'
import PremiumService from '@/pages/services/PremiumService.vue'
import RpcService from '@/pages/services/RpcService.vue'
import SnapshotsService from '@/pages/services/SnapshotsService.vue'
import NotFound from '@/pages/NotFound.vue';
import ServerError from '@/pages/ServerError.vue';
import ContactUs from '@/pages/ContactUs.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/about_us', component: AboutUs },
  { path: '/nodes', component: NodeList },
  { path: '/privacy_policy', component: PrivacyPolicy },
  { path: '/terms_of_use', component: TermsOfUse },
  { path: '/services/delegate', component: DelegateService },
  { path: '/services/premium', component: PremiumService },
  { path: '/services/rpc', component: RpcService },
  { path: '/services/snapshots', component: SnapshotsService },
  { path: '/contact_us', component: ContactUs },
  { path: '/ServerError', component: ServerError },
  { path: '/:pathMatch(.*)*', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
