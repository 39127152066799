<template>
    <div class="container my-5">
      <h1>Qollective's Premium Node-on-demand RPCs</h1>
      <p>Some users and projects need more than a public RPC to suit their needs.<br>
      This is why the Qollective offers Node-on-demand services.<br>
      Designed for high-end users and organisations that need the best in performance and security, our state-of-the-art RPC nodes offer a dedicated throughput, best-in-class resilience, and custom location so that your node can be close to your organisation.
</p>

<p>In addition to their impressive performance, our premium RPC nodes also offers advanced security and authentication features.</p>
<p>Interested ? Got questions ? <a href="/contact_us">Contact us</a> and we'll get back to you as soon as possible.</p>
</div>

</template>


<script>
export default {
  name: 'PremiumService',
}
</script>