<template>
    <div class="container my-5">
      <div class="row">
        <div class="col">
          <h2>Connecting to the Qollective's RPC</h2>
              <table class="table">
                <tbody>
                  <tr>
                    <td rowspan="5" class="text-center align-middle">For Q Mainnet</td>
                    <th scope="row">Network name:</th>
                    <td>Q Mainnet</td>
                  </tr>
                  <tr>
                    <th scope="row">New RPC URL:</th>
                    <td>https://qollective.io/rpc</td>
                  </tr>
                  <tr>
                    <th scope="row">Chain ID:</th>
                    <td>35441</td>
                  </tr>
                  <tr>
                    <th scope="row">Currency Symbol:</th>
                    <td>Q</td>
                  </tr>
                  <tr>
                    <th scope="row">Block explorer URL:</th>
                    <td>https://explorer.q.org</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td rowspan="5" class="text-center align-middle">For Q Testnet</td>
                    <th scope="row">Network name:</th>
                    <td>Q Testnet</td>
                  </tr>
                  <tr>
                    <th scope="row">New RPC URL:</th>
                    <td>https://qollective.io/testnet/rpc</td>
                  </tr>
                  <tr>
                    <th scope="row">Chain ID:</th>
                    <td>35443</td>
                  </tr>
                  <tr>
                    <th scope="row">Currency Symbol:</th>
                    <td>Q</td>
                  </tr>
                  <tr>
                    <th scope="row">Block explorer URL:</th>
                    <td>https://explorer.qtestnet.org</td>
                  </tr>
                </tbody>
              </table>
            <p>For more details on how to add a custom network to Metamask, please <a href="https://support.metamask.io/hc/en-us/articles/360043227612-How-to-add-a-custom-network-RPC">visit the Metamask website</a></p>
        </div>
      </div>
    </div>

</template>


<script>
export default {
  name: 'RpcService',
}
</script>