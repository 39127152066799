// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLinkedin, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { faStar } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { Buffer } from 'buffer';
import process from 'process/browser';
window.process = process;
window.Buffer = Buffer; // Make Buffer available globally

/* add icons to the library */
library.add(faLinkedin, faGithub, faTwitter, faStar, far)

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@/../public/css/main.css'
import '@/../public/css/custom.css'

const app = createApp(App)

app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
